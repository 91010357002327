@font-face {
	font-family: 'icomoon';
	src: url('./fonts/icomoon/icomoon.eot?rv35wy');
	src: url('./fonts/icomoon/icomoon.eot?rv35wy#iefix') format('embedded-opentype'),
	url('./fonts/icomoon/icomoon.ttf?rv35wy') format('truetype'),
	url('./fonts/icomoon/icomoon.woff?rv35wy') format('woff'),
	url('./fonts/icomoon/icomoon.svg?rv35wy#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"], [class*="icomoon"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
	content: "\e903";
}

.icon-check:before {
	content: "\e902";
}

.icon-down_arrow:before {
	content: "\e901";
}

.icon-group:before {
	content: "\e900";
}
