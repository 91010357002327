.CalendarMonth_table {
	border-collapse: separate;
}

.CalendarDay {
	border-radius: 50%;
	color: black;
	
	&__default {
		&,
		&:active,
		&:hover {
			border: 1px solid transparent;
			background-color: white;
		}
	}
}
